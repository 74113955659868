import React from 'react'
import { Fade } from "react-awesome-reveal";

function Contactpage() {
    return (
        <div className="contactPage">
            <Fade delay={300}>
                <div className="sectionTitle">
                    CONTACT
                </div>

                <div className="locations">

                    <article className="location">
                        <section className="title" style={{'color' : '#ecab20'}}>
                            ALMA
                        </section>
                        <section className="adress">
                            <a style={{ color: 'inherit', textDecoration: 'inherit'}} 
                            href="https://goo.gl/maps/HEWEZjpQiR46Mtom6">
                                1430, boul. Saint‑Jude Sud Alma <br/>(Québec) G8B 3L4
                            </a>
                        </section>
                        <section className="tel">
                            T 418 662.5476
                        </section>
                    </article>

                    <article className="location">
                        <section className="title" style={{'color' : '#9c1b30'}}>
                            LONGUEUIL
                        </section>
                        <section className="adress">
                            <a style={{ color: 'inherit', textDecoration: 'inherit'}} 
                             href="https://goo.gl/maps/m7jcG1ehuBu8ymgG7">
                            2751, boul. Jacques‑Cartier Est Longueuil <br/>(Québec) J4N 1L7
                            </a>
                        </section>
                        <section className="tel">
                            T 450 670.9222
                        </section>
                    </article>

                    <article className="location">
                        <section className="title" style={{'color' : '#004e7d'}}>
                            MONTRÉAL
                        </section>
                        <section className="adress">
                            <a style={{ color: 'inherit', textDecoration: 'inherit'}} 
                            href="https://goo.gl/maps/G9d9MiL4QFjtxLdM8">
                            6600, Saint-Urbain, suite 102 Montréal <br/>(Québec) H2S 3G8
                            </a>
                        </section>
                        <section className="tel">
                            T 514 277.6022
                        </section>
                    </article>

                </div>
                <div className="suppinfo">
                    <article className="location">
                    <br/><br/><br/>
                        <section className="title" style={{'color' : 'gray'}}>
                            RESSOURCES HUMAINES
                        </section>
                        <section className="adress">
                            <a style={{ color: 'inherit', textDecoration: 'inherit', fontSize: '90%'}} 
                            href="https://goo.gl/maps/G9d9MiL4QFjtxLdM8">
                            Vous souhaitez rejoindre nos équipes ? <br/>
                            Transmettez-nous votre candidature en précisant le(s) poste(s) recherché(s) a :
                            </a>
                        </section>
                        <section className="tel">
                            recrutement@graphiscan.com
                        </section>
                    </article>


                    <article className="location">
                    <br/><br/><br/>
                        <section className="title" style={{'color' : 'gray'}}>
                            PROTECTION DES RENSEIGNEMENTS PERSONNELS
                        </section>
                        <section className="adress">
                            <a style={{ color: 'inherit', textDecoration: 'inherit', fontSize: '90%'}} 
                            href="https://goo.gl/maps/G9d9MiL4QFjtxLdM8">
                            Si vous avez des questions concernant la manière dont nous traitons vos renseignements personnels, ou pour exercer vos droits, veuillez communiquer avec notre Responsable de la protection des renseignements personnels par courriel ou par courrier aux coordonnées suivantes :
                            <br/>
                            <b>
                            2751 Bd Jacques-Cartier Est
                            Longueuil (Québec) J4N 1L7
                            </b>
                            </a>
                        </section>
                        <section className="tel">
                            supportti@graphiscan.com
                        </section>
                    </article> 

                </div>
            </Fade>
        </div>
    )
}

export default Contactpage
